import React from 'react'; 
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Figure from 'react-bootstrap/Figure';
import enterpreneurIcon from '../../../css/images/enterpreneur-icon.svg';
import lateralThinkingIcon from '../../../css/images/lateral-thinking.svg';
import pushinglimitIcon from '../../../css/images/pushing-limits.svg';
import sincereIcon from '../../../css/images/sincere-icon.svg';
import joyfulIcon from '../../../css/images/joyful-icon.svg';


function OurCoreValueModule() {
    return ( 

        <div className='our-core-main-layer'>
            <div className='our-core-header-layout'>
                <h2>What makes you a Pitcher?</h2>
                <p>At the heart of every Pitcher are <b>our core values</b>.</p>
            </div>
            <div className='our-core-inner-layout'>
            <Container fluid>
                <Row className='firstRow'>
                    <Col xs lg="4" sm={6}>
                    <Figure>
                        <Figure.Image
                            width={120}
                            height={120}
                            alt="Entrepreneur"
                            src={enterpreneurIcon}
                        />
                        <div className='our-core-inner-details'>
                        <Figure.Caption>
                            Entrepreneur
                        </Figure.Caption>
                        <h4>By Attitude</h4>
                        </div>
                    </Figure>
                    </Col>
                    <Col xs lg="4" sm={6}>
                    <Figure>
                        <Figure.Image
                            width={120}
                            height={120}
                            alt="Lateral Thinking"
                            src={lateralThinkingIcon}
                        />
                        <div className='our-core-inner-details'>
                         <Figure.Caption>
                         Laterally Thinking 
                        </Figure.Caption>
                        <h4>By Habit</h4>
                        </div>
                    </Figure>
                    </Col>
                    <Col xs lg="4" sm={6}>
                    <Figure>
                        <Figure.Image
                            width={120}
                            height={120}
                            alt="Pushing Limits"
                            src={pushinglimitIcon}
                        /> 
                        <div className='our-core-inner-details'>
                         <Figure.Caption>
                         Pushing Limits
                        </Figure.Caption>
                        <h4>By Practice</h4>
                        </div>
                    </Figure>
                    </Col>
                    </Row>
                    <Row className='secondRow'>
                    <Col xs lg="2" sm={6} className='removeDiv'>
                    </Col> 
                    <Col xs lg="4" sm={6}>
                    <Figure>
                        <Figure.Image
                            width={120}
                            height={120}
                            alt="Sincere"
                            src={sincereIcon}
                        /> 
                        <div className='our-core-inner-details'>
                         <Figure.Caption>
                         Sincere
                        </Figure.Caption>
                        <h4>By Nature</h4>
                        </div>
                    </Figure>
                    </Col>
                    <Col xs lg="4" sm={6}>
                    <Figure>
                        <Figure.Image
                            width={120}
                            height={120}
                            alt="Joyful"
                            src={joyfulIcon}
                        />
                        <div className='our-core-inner-details'>
                         <Figure.Caption>
                            Joyful 
                        </Figure.Caption>
                        <h4>By Choice</h4>
                        </div>
                    </Figure>
                    </Col>
                    <Col xs lg="2" sm={6} className='removeDiv'>
                    </Col>
                </Row>
             </Container>

            </div>
        </div>
    ); 

}

export default OurCoreValueModule;

