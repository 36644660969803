import React from 'react';
import { Link } from "react-router-dom";

const Footer = ({ data }) => {
  return (
  
    <div className="footer-main">
      {data.map((row, rowIndex) => (
        <div className={`footer-row ${rowIndex === 1 ? 'extra-class' : ''}`} key={rowIndex}>
          {row.map((column, columnIndex) => (
            <div className="footer-colmn" key={columnIndex}>
              {column.type === 'image' ? (
                <div className='footer-logo'>
                  <Link to="//www.thehigherpitch.com/"><img src={column.src} alt={column.alt} /></Link>
                </div>
              ) : (
                <div>
                  <div className="heading">{column.heading}</div>
                  {column.subheadings ? (
                    <div className="subheadings">
                      {column.subheadings.map((subheading, subheadingIndex) => (
                        <div key={subheadingIndex} className="subheading">
                          <a href={subheading.url} target="_blank" rel="noopener noreferrer">
                              {subheading.text}
                          </a>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div>
                      <div className="paragraph" dangerouslySetInnerHTML={{ __html: column.paragraph }} />
                      <div className="contact-info">
                        <div className="image">
                          <img src={column.imageSrc} alt="Phone" />
                        </div>
                        <div className="phone">
                            <a href={`tel:${column.phoneNumber}`}>{column.phoneNumber}</a>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          ))}
        </div>
      ))}
      {/* Third Row */}
      
    </div>
 
  );
};

export default Footer;
