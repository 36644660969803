import React, { useState } from 'react';
import { Link } from "react-router-dom";
import '../../../css/header.css'; // Import the CSS file with styles
import logo from '../../../css/images/header/header-logo.svg';
import HeaderLogo from '../../../css/images/footer/thp-footer-logo-new.svg';
import HumbergerLogo from '../../../css/images/header/menu-hamburger.svg';
import closeIcon from '../../../css/images/header/header_close-icon.svg';
import linkedinIcon from '../../../css/images/header/linkedin-icon.svg';
import twitterIcon from '../../../css/images/header/twitter-icon.svg';
import instagramIcon from '../../../css/images/header/instagram-icon.svg';
import facebookIcon from '../../../css/images/header/facebook-icon.svg';
import youtubeIcon from '../../../css/images/header/youtube-icon.svg';
import glassdoorIcon from '../../../css/images/header/glassdoor-icon.svg'


const SideNav = () => {
const [isSideNavOpen, setIsSideNavOpen] = useState(false);

const openNav = () => {
setIsSideNavOpen(true);
};

const closeNav = () => {
setIsSideNavOpen(false);
};

return (
<div className="header_section">
<div className="container-fluid">
<div className={`header-row ${isSideNavOpen ? 'open' : ''}`}>
    <div className="logo">
       <Link to="//www.thehigherpitch.com/"> <img src={logo} alt="Logo" /></Link>
    </div>
    <div className="menu">
    <a href="#" className='dropdown'>
        <span className='dropbtn'>We</span>
        <div class="dropdown-content">
            <a href="https://www.thehigherpitch.com/brand-value/">Increase Brand Value</a>
        </div>
    </a>
    <a href="https://www.thehigherpitch.com/blogs/"><span>Think</span></a>
    <a href="https://www.thehigherpitch.com/the-company/"><span>About</span></a>
    <a href="https://www.thehigherpitch.com/customer-success/"><span>You</span></a>
    </div>
    <span
    className="toggle-icon"
    style={{ fontSize: '30px', cursor: 'pointer' }}
    onClick={openNav}
    >
    <img src={HumbergerLogo} width="100" height="50" />
    </span>
</div>
<div id="mySidenav" className="sidenav" style={{ width: isSideNavOpen ? '100%' : '0' }}>
    <div className="custom-menu-section">
    <div className="container-fluid">
    <div className="inner-menu">
    {/* Replace "Menu" with your desired text */}
    <div className="menu-row" id='hamberger_inner_header-layout'>
        <div className="menu-column">
        <Link to="https://www.thehigherpitch.com/"><img src={HeaderLogo} alt="Logo" /></Link>
        </div>
        <div className='menu-close-column'>
            <a href="javascript:void(0)" className="closebtn" onClick={closeNav}>
                    <img src={closeIcon} width="25" height="25" />
            </a>
        </div>
    </div>
    <div className="menu-row" id='humberger_inner_body_layout'>
      <div className='humberger_inner_body_layer'>
        <div className="menu-column menu_25x_width">
            <div className="menu-heading">
                <h2>We</h2>
            </div>
            <div className="menu-subheading">
                <ul>
                    <li><a href="https://www.thehigherpitch.com/brand-value/">Increase Brand Value</a></li>
                </ul>
            </div>
        </div>
        <div className="menu-column menu_25x_width">
            <div className="menu-heading">
                <h2>Think</h2>
            </div>
            <div className="menu-subheading">
                <ul>
                    <li><a href="https://www.thehigherpitch.com/blogs/">Insights</a></li>
                </ul>
            </div>
        </div>
        <div className="menu-column menu_25x_width">
            <div className="menu-heading">
                <h2>About</h2>
            </div>
            <div className="menu-subheading">
                <ul>
                    <li><a href="https://www.thehigherpitch.com/the-company/">The Company</a></li>
                    <li><a href="https://www.thehigherpitch.com/the-company/#the-vision">The Vision</a></li>
                    <li><a href="https://www.thehigherpitch.com/the-company/#the-value">The Value</a></li>
                    <li><a href="https://www.thehigherpitch.com/the-company/#the-leaderShip">Leadership</a></li>
                    <li><a href="https://www.thehigherpitch.com/contact-us/">Contact Us</a></li>
                    <li><a href="https://careers.thehigherpitch.com/">Careers</a></li>
                </ul>
            </div>
        </div>
        <div className="menu-column menu_25x_width">
            <div className="menu-heading">
                <h2>You</h2>
            </div>
            <div className="menu-subheading">
                <ul>
                    <li><a href="https://www.thehigherpitch.com/customer-success/">Customer Success</a></li>
                </ul>
            </div>
        </div>
     </div>
        <div className='our_studio_layout'>
            <div className='our_studio_body-layout'>
                <h4>Our Studio</h4>
                <ul>
                    <li><a href='https://www.thehigherpitch.com/ux-studio/'>UX</a></li>
                    <li><a href='https://www.thehigherpitch.com/digital-marketing/'>Digital Marketing</a></li>
                    <li><a href='https://www.thehigherpitch.com/demand-generation/'>Demand Generation</a></li>
                    <li><a href='https://www.thehigherpitch.com/content-and-communication/'>Content & Communications</a></li>
                    <li><a href='https://www.thehigherpitch.com/creative-and-design/'>Creative & Design</a></li>
                    <li><a href='https://www.thehigherpitch.com/brand/'>Brand</a></li>
                    <li><a href='https://www.thehigherpitch.com/research-services/'>Research</a></li>
                    <li><a href='https://www.thehigherpitch.com/pr-and-media/'>PR & Media</a></li>
                    <li><a href='https://www.thehigherpitch.com/technology/'>Technology</a></li> 
                </ul>
            </div>
        </div>
        <div className='himberger_menu_inner_footer_module'>
            <div className='reach-us-heading'>
                        <h5>Reach Us at</h5>
                </div> 
            <div className='humberger-menu-inner-footer-layout' id='humbergerMenuInnerFooterLayer'>
                <div className='inner-footer-column'>
                    <p><a href="mailto:play@thehigherpitch.com">play@thehigherpitch.com</a></p>
                </div>
                <div className='inner-footer-column'>
                    <p><a href="tel:+91 120-5106719">India +91 120-5106719</a></p>
                </div>
                <div className='inner-footer-column'>
                    <p><a href="tel:+1 412 631 8384">USA +1 412 631 8384</a></p>
                </div>
                <div className='inner-footer-column'>
                    <div className='humberger-inner-social-layout'>
                        <ul>
                            <li>
                                <a href='https://www.linkedin.com/company/the-higher-pitch/' target="_blank">
                                    <img src={linkedinIcon} width={20} height={20} alt='LinkedIn Icon'/>
                                </a>
                            </li>
                            <li>
                                <a href='https://twitter.com/thehigherpitch/' target="_blank">
                                    <img src={twitterIcon} width={20} height={20} alt='Twitter Icon'/>
                                </a>
                            </li>
                            <li>
                                <a href='https://www.instagram.com/thehigherpitch/' target="_blank">
                                    <img src={instagramIcon} width={20} height={20} alt='Instagram Icon'/>
                                </a>
                            </li>
                            <li>
                                <a href='https://www.facebook.com/thehigherpitch/' target="_blank">
                                    <img src={facebookIcon} width={20} height={20} alt='Facebook Icon'/>
                                </a>
                            </li>
                            <li>
                                <a href='https://www.youtube.com/channel/UCN9zk8_AYQSbC7UV9oq6HYA/' target="_blank">
                                    <img src={youtubeIcon} width={20} height={20} alt='Youtube Icon'/>
                                    </a>
                            </li>
                            <li>
                                <a href='https://www.glassdoor.co.in/Overview/Working-at-The-Higher-Pitch-EI_IE1816987.11,27.htm' target="_blank">
                                    <img src={glassdoorIcon} width={20} height={20} alt='Glassdoor Icon'/>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
    </div>
    </div>
</div>
</div>
</div>
);
};

export default SideNav;
