import React from 'react'; 
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import bgIcon from '../../../css/images/perk-pichers-icons/icon-bg-img.svg';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));
function PerkPichersModule() {
    return (
        <div className="perk-pichers-main">
        <Container fluid>
            <Row>
            <div className='perk-pichers-header'>
                <h2>Perks of being a Pitcher</h2>
            </div>
            </Row>
            <Row>
            <div className='perk-pichers-inner-layout'>
            <Box sx={{ flexGrow: 1 }}>
              {/* <Grid container spacing={2} columns={12}> */}
              <Grid container spacing={2}>
                    {/* <Grid item xs={3}> */}
                    <Grid item xs={12} sm={12} md={4} lg={3}>
                        <Item>
                           <div className='perk-pichers-details'>
                                <h5>Karaoke Evenings</h5> 
                          </div> 
                          <div className='perk-pichers-icon-layout'> 
                            <img src={bgIcon} width="100%" height="auto" alt="Banner Icon" />
                          </div>
                        </Item>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={3}>
                        <Item>
                           <div className='perk-pichers-details'>
                                <h5>Beverages on the House</h5>
                          </div> 
                          <div className='perk-pichers-icon-layout'> 
                            <img src={bgIcon} width="100%" height="auto" alt="Banner Icon" />
                          </div>
                        </Item>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={3}>
                        <Item>
                           <div className='perk-pichers-details'>
                                <h5>Food Coupons</h5>
                          </div> 
                          <div className='perk-pichers-icon-layout'> 
                            <img src={bgIcon} width="100%" height="auto" alt="Banner Icon" />
                          </div>
                        </Item>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={3}>
                        <Item>
                           <div className='perk-pichers-details'>
                                <h5>Period WFH for Women</h5>
                          </div> 
                          <div className='perk-pichers-icon-layout'> 
                            <img src={bgIcon} width="100%" height="auto" alt="Banner Icon" />
                          </div>
                        </Item>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={3}>
                        <Item>
                           <div className='perk-pichers-details'>
                                <h5>Maternity Leave</h5>
                          </div> 
                          <div className='perk-pichers-icon-layout'> 
                            <img src={bgIcon} width="100%" height="auto" alt="Banner Icon" />
                          </div>
                        </Item>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={3}>
                        <Item>
                           <div className='perk-pichers-details'>
                                <h5>Paternity Leave</h5>
                          </div> 
                          <div className='perk-pichers-icon-layout'> 
                            <img src={bgIcon} width="100%" height="auto" alt="Banner Icon" />
                          </div>
                        </Item>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={3}>
                        <Item>
                           <div className='perk-pichers-details'>
                                <h5>Bereavement Leave</h5>
                          </div> 
                          <div className='perk-pichers-icon-layout'> 
                            <img src={bgIcon} width="100%" height="auto" alt="Banner Icon" />
                          </div>
                        </Item>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={3}>
                        <Item>
                           <div className='perk-pichers-details'>
                                <h5>Adoption Leave</h5>
                          </div> 
                          <div className='perk-pichers-icon-layout'> 
                            <img src={bgIcon} width="100%" height="auto" alt="Banner Icon" />
                          </div>
                        </Item>
                    </Grid>
                </Grid>
            </Box>
            </div>
            </Row>
        </Container>
        </div>
    
    );
  }
  
  export default PerkPichersModule;