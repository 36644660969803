import React from 'react'; 
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Slider from "react-slick";
import SliderImageOne from '../../../css/images/slider-images/slider-one-img.png';
import SliderImageTwo from '../../../css/images/slider-images/slider-two-img.png';
import SliderImageThree from '../../../css/images/slider-images/slider-three-img.png';



function Oppertunities() {
    const settings = {
        className: "slider variable-width",
        dots: false,
        infinite: true,
        centerMode: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: true,
                arrows: false,
                dots: true,
              }
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: true,
                arrows: false,
                dots: true,
              }
            },
            {
            breakpoint: 600,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                arrows: false,
                dots: true,
                variableWidth: false
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                arrows: false,
                dots: true,
                variableWidth: false,
              }
            }
          ]
      };
    return (
        <Container fluid>
         <Row>
            <div className="oppertunities-main">
                <h2>Creating better opportunities for you</h2>
                <p>We are a band of competent, compassionate, complementary, and capable enthusiasts who are always searching for unique talent. If you believe in an egalitarian, diverse, and autonomous workplace where fun and work go together, your search has ended.</p>
            </div>
            <div className='pitchers-life-main'>
                <h3>Life of Pitchers</h3>
                <p>Here is a glimpse of all the fun we had together!</p>
                <div className='pitchers-slider-layout'>
                        <Slider {...settings}>
                            <div style={{ width: 350 }} className='image-slider'>
                                <img src={SliderImageOne} width="100%" height="auto" alt="Slider Image" />
                            </div>
                            <div style={{ width: 300 }} className='image-slider'>
                                <img src={SliderImageTwo} width="100%" height="auto" alt="Slider Image" />
                            </div>
                            <div style={{ width: 330 }} className='image-slider'>
                                <img src={SliderImageThree} width="100%" height="auto" alt="Slider Image" />
                            </div>
                            <div style={{ width: 350 }} className='image-slider'>
                                <img src={SliderImageOne} width="100%" height="auto" alt="Slider Image" />
                            </div>
                            <div style={{ width: 300 }} className='image-slider'>
                                <img src={SliderImageTwo} width="100%" height="auto" alt="Slider Image" />
                            </div>
                            <div style={{ width: 350 }} className='image-slider'>
                                <img src={SliderImageThree} width="100%" height="auto" alt="Slider Image" />
                            </div>
                        </Slider>
                </div>
            </div>
         </Row>
        </Container>
    );
  }
  
  export default Oppertunities;