import React from 'react'; 

function FollowModule() {
    return (
      <div className="follow-us-main">
        <div className='follow-us-text'>Follow us on <a href='#'>#PlayTheHigherPitch</a> to stay updated with our shenanigans.</div>
      </div>
    );
  }
  
  export default FollowModule;
  