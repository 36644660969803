import React from 'react'; 

import Container from '@mui/material/Container';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Slider from "react-slick";
import pichersVoiceIcon from '../../../css/images/pichers-voice/voice-icon.svg';
import pichersVoiceAnchalImage from '../../../css/images/pichers-voice/anchal-updated-img.png';
import pichersVoiceSreoshiImage from '../../../css/images/pichers-voice/sreoshi-bakshi-img.jpg';
import pichersVoiceDigvijayImage from '../../../css/images/pichers-voice/digvijay-dubey-img.jpg';
import pichersVoiceIpshitaImage from '../../../css/images/pichers-voice/ipshita-das-img.png';
import pichersVoiceRishabhImage from '../../../css/images/pichers-voice/rishabh-tyagi-img.jpeg';
import pichersVoiceAnchalOjhaImage from '../../../css/images/pichers-voice/anchal-ojha-img.jpeg';
import pichersVoiceShivamTikooImage from '../../../css/images/pichers-voice/shivam-tickoo-img.jpg';
import pichersVoiceAkhilImage from '../../../css/images/pichers-voice/akhil-bardwaj-img.png';


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));
function PicherVoiceModule() {
  const pichersSlider = {
    className: "slider fade-slider",
    dots: false,
    infinite: true,
    speed: 500,
    fade: true,
    cssEase: 'linear',
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          arrows: false,
          dots: true,
          autoplay: true,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          arrows: false,
          dots: true,
          autoplay: true,
        }
      },
      {
      breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          arrows: false,
          dots: true,
          autoplay: true,
          variableWidth: false
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          arrows: false,
          dots: true,
          autoplay: true,
          variableWidth: false,
        }
      }
    ]
  };
    return (
      <div className="pichers-voice-main">
        <React.Fragment>
            <Container>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <div className='pichers-voice-left-layer'>
                    <h2>Pitcher Voices</h2>
                    <div className='pichers-voice-icon-bg'>
                        <img src={pichersVoiceIcon} width="94" height="auto" alt="Icon" />
                    </div>
                    <h4>Do not just go by what we say,</h4>
                    <h3>Hear it from our team as well!</h3>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} className='pichers-voice-right-grid'>    
                    <div className='pichers-voice-right-layer'>
                    <div className="pichers-voice-slide">
                    <Slider {...pichersSlider}>
                    <div className='voice-slider-inner-layer'>
                          <p className='voice-author-text'>In 2016, I proudly joined THP as its second employee. My journey has been marked by growth in skills and resilience. Amidst challenges like COVID-19, process changes, and organizational adjustments, we stood united. Gratitude to committed directors, inspirational seniors, and collaborative colleagues, this journey is etched in my memory.</p>
                          <div className='voice-slider-author-summery'>
                            <div className='voice-author-image-area'>
                            <img src={pichersVoiceAkhilImage} width="100%" height="auto" alt="Author Image" />
                            </div>
                            <div className='voice-author-details-area'> 
                              <h3>Akhil Bhardwaj</h3>
                              <p>Senior Manager- Administration and facilities</p>
                            </div>
                          </div>
                        </div>
                        <div className='voice-slider-inner-layer'>
                          <p className='voice-author-text'>My five years at THP has been a rollercoaster of growth and achievement. One standout moment was when we successfully rebranded a client, taking their market share to new heights. I'm part of a dynamic team where each success, big or small, is a shared victory.</p>
                          <div className='voice-slider-author-summery'>
                            <div className='voice-author-image-area'>
                            <img src={pichersVoiceDigvijayImage} width="100%" height="auto" alt="Author Image" />
                            </div>
                            <div className='voice-author-details-area'> 
                              <h3>Digvijay Dubey</h3>
                              <p>Team Lead- Digital technology</p>
                            </div>
                          </div>
                        </div>
                        <div className='voice-slider-inner-layer'>
                          <p className='voice-author-text'>At THP, we don't just play the pitch for brand transformation; we elevate careers and live to a higher note. Proud to be on THP's rocket ship, where the sky isn't the limit- it's the launchpad to incredible possibilities. As a proud pitcher, every day is a new pitch-perfect adventure!</p>
                          <div className='voice-slider-author-summery'>
                            <div className='voice-author-image-area'>
                            <img src={pichersVoiceAnchalImage} width="100%" height="auto" alt="Author Image" />
                            </div>
                            <div className='voice-author-details-area'> 
                              <h3>Anchal Swing</h3>
                              <p>Principal Consultant- Human Resources</p>
                            </div>
                          </div>
                        </div>
                        <div className='voice-slider-inner-layer'>
                          <p className='voice-author-text'>At THP, learning new things from different departments to sharing ideas with peers, I have been able to elevate my skills and connect with some of the sharpest minds that I could ever come across.</p>
                          <div className='voice-slider-author-summery'>
                            <div className='voice-author-image-area'>
                            <img src={pichersVoiceSreoshiImage} width="100%" height="auto" alt="Author Image" />
                            </div>
                            <div className='voice-author-details-area'> 
                              <h3>Sreoshi Bakshi</h3>
                              <p>Deputy Manager, Content and Communications</p>
                            </div>
                          </div>
                        </div>
                        <div className='voice-slider-inner-layer'>
                          <p className='voice-author-text'>THP is the first B2B stint of my career, and I am glad I got to experience it. One thing that impresses me the most is the attitude of all employees here. I always get to see a sense of loyalty in most members of the team, that's both inspiring and infectious.</p>
                          <div className='voice-slider-author-summery'>
                            <div className='voice-author-image-area'>
                            <img src={pichersVoiceIpshitaImage} width="100%" height="auto" alt="Author Image" />
                            </div>
                            <div className='voice-author-details-area'> 
                              <h3>Ipshita Das</h3>
                              <p>Senior Executive- Content and Communications</p>
                            </div>
                          </div>
                        </div>
                        <div className='voice-slider-inner-layer'>
                          <p className='voice-author-text'>THP has been the perfect place for my professional growth. Working here has given me the opportunity to be part of transformative journeys, where every project is a chance to redefine a brand's identity.</p>
                          <div className='voice-slider-author-summery'>
                            <div className='voice-author-image-area'>
                            <img src={pichersVoiceRishabhImage} width="100%" height="auto" alt="Author Image" />
                            </div>
                            <div className='voice-author-details-area'> 
                              <h3>Rishabh Tyagi</h3>
                              <p>Assistant Manager- Content and Communications</p>
                            </div>
                          </div>
                        </div>
                        <div className='voice-slider-inner-layer'>
                          <p className='voice-author-text'>From day one, I felt supported and encouraged to explore my potential. The company's commitment to professional development has allowed me to grow not only as an employee but as an individual.</p>
                          <div className='voice-slider-author-summery'>
                            <div className='voice-author-image-area'>
                            <img src={pichersVoiceAnchalOjhaImage} width="100%" height="auto" alt="Author Image" />
                            </div>
                            <div className='voice-author-details-area'> 
                              <h3>Anchal Ohja</h3>
                              <p>Executive- Content and Communications</p>
                            </div>
                          </div>
                        </div>
                        <div className='voice-slider-inner-layer'>
                          <p className='voice-author-text'>THP places a strong emphasis on work-life balance, which has had a significant impact on my overall happiness and productivity. the professional development opportunities and training programs available have allowed me to enhance my skills and advance in my career.</p>
                          <div className='voice-slider-author-summery'>
                            <div className='voice-author-image-area'>
                            <img src={pichersVoiceShivamTikooImage} width="100%" height="auto" alt="Author Image" />
                            </div>
                            <div className='voice-author-details-area'> 
                              <h3>Shivam Tickoo</h3>
                              <p>Manager, Demand Generation</p>
                            </div>
                          </div>
                        </div>
                    </Slider>
                    </div>
                    </div>
                    
                </Grid>
              </Grid>
            </Box>
            </Container>
        </React.Fragment>
      </div>
    );
  }
  
  export default PicherVoiceModule;