import React, { useState, useEffect } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Figure from 'react-bootstrap/Figure';
import bannerImage from '../../css/images/landing-banner-bg-img.png';
import bannerArrowIcon from '../../css/images/arrow-icon.svg';
import innovationImage from '../../css/images/Innovation.svg';
import imageTwo from '../../css/images/image-2.svg';
import imageThree from '../../css/images/image-3.svg';
import OurCoreValueModule from '../../Components/careers/careerInnerModule/OurCoreValue.js';
import OppertunitiesModule from '../../Components/careers/careerInnerModule/Opportunities.js';
import PerkPichersModule from '../../Components/careers/careerInnerModule/PerkPichers.js';
import PichersVoiceModule from '../../Components/careers/careerInnerModule/PicherVoice.js';
import CurrentOpeningsModule from '../../Components/careers/careerInnerModule/CurrentOpeneings.js';
import FollowUsModule from '../../Components/careers/careerInnerModule/FollowUs.js';


function LandingTemplate() {
  const [loading, setLoading] = useState(true);
    
  useEffect(() => {
    // Simulate loading delay or fetch data here
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 2000); // 2000 milliseconds (adjust as needed)

    // Cleanup timeout to avoid memory leaks
    return () => clearTimeout(timeout);
  }, []);
  /*  has scroll View method */
    const handleScrollExploreJobs = () => {
      const submitYourAppElement = document.getElementById('currentJobOpenings');
      if (submitYourAppElement) {
        submitYourAppElement.scrollIntoView({ behavior: 'smooth' });
      }
    };
    /* Ends Here */ 
console.debug("LandingTemplate")
  
    return (
      <div className="main-template">
         {loading && (
        <div className="loading-backdrop">
          <CircularProgress style={{ color: '#9FA6B2' }} />
        </div>
      )}
          <div className="banner_module">
          <img src={bannerImage} width="100%" height="auto" alt="Banner Image" />
          <div className="text-block">
            <h1>Start the journey</h1>
            <p>to a higher fit</p>
            <Button variant="outline-light" onClick={handleScrollExploreJobs}>Explore Jobs <img src={bannerArrowIcon} width="15" height="auto" alt="arrow-icon" /></Button>{' '}
          </div>
          <div class="blink-arrow">
            <a href="#second-layer">
              <div id="mouse-scroll">
                <div>
                    <span class="down-arrow-1"></span>
                    <span class="down-arrow-2"></span>
                </div>
              </div>   
            </a>
          </div>
          </div>  

          <Container fluid id='second-layer'>
            <Row>
            <div className='second-module'>
              <p>At <b>The Higher Pitch,</b> we vibe together and thrive together. Our work environment is conducive to creative thinking in a hybrid world. Our work for marquee clients around the globe exemplifies our passion and commitment to executing their vision. Whether researching our developing robust intel to better our clients’ demand generation or strategizing content and design to boost our clients’ brand visibility, we always put our best foot forward and leave an impact. That keeps us a notch above the rest (the coffee helps, too!).</p>
              <p>We are inclusive and boast a unique blend of diversity in gender, age, ethnicity, culture, backgrounds, and experiences. Once you are a Pitcher, we nurture your true potential and align your professional growth with The Higher Pitch's. This is not just a career; it is a way of life.</p>
             </div>
            </Row> 
            <div className='second-center-module'>
            <Container>
              <Row>
                <div className='centered-inner-layer'>
                <p>If you move to the beat of the same drum, join us, and together,</p>
                <h4>let’s play The Higher Pitch!</h4>
                </div>
              </Row>
              </Container>
              </div>
        </Container>
        <div className='third-layout'>
        <Container fluid>
          <Row>
            <Col className='left-col' lg="8" sm={12}>
            <h3>How do we make an impact?</h3>
            <p>In the ever-changing world that we live in, we believe the key to <b>growth</b> is agility. Driven by challenges, <b>innovation,</b> and <b>technology</b>, we strive to make an impact everyday.</p>
            </Col>
            <Col className='right-col' xs lg="4" sm={12}>
              <div className='right-col-inner-layout'>
              <Figure>
              <Figure.Image
                width={225}
                height={225}
                alt="Innovation image"
                src={innovationImage}
              />
                <Figure.Image
                width={172}
                height={172}
                alt="Image"
                src={imageTwo}
              />
              <Figure.Image
                width={196}
                height={196}
                alt="Image"
                src={imageThree}
              />
              </Figure>
              </div>

            </Col>
          </Row>
        </Container>
        </div>
        <div className='fourt-layout'>
          <OurCoreValueModule />
        </div>
        <div className='fifth-layout'>
            <OppertunitiesModule />
        </div>
        <div className='sixth-layout'>
            <PerkPichersModule />
        </div>
        <div className='seventh-layout'>
            <PichersVoiceModule />
        </div>
        <div className='eighth-layout' id='currentJobOpenings'>
            <CurrentOpeningsModule />
        </div>
        <div className='ninth-layout'>
            <FollowUsModule />
        </div>
       
      
      </div>
    );
  
  }
  
  export default LandingTemplate;