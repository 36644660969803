import React, { useState, useEffect } from "react";
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Link } from "react-router-dom";
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import APIService from '../../../services/apiService.jsx';
import { Box, CircularProgress, MenuItem } from "@mui/material";
import bannerArrowIcon from '../../../css/images/arrow-icon.svg';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: 'rgba(3, 8, 116, 0.90)',
    fontSize: 20,
    fontWeight: 600,
    lineHeight: 'normal',
    color: '#ffffff',
    textAlign: 'center'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 20,
    fontWeight: 500,
    lineHeight: 'normal',
    color: '#646464',
    textAlign: 'center',
    textTransform: 'capitalize'
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: '#ffffff',
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const itemsPerPage = 3;

function CurrentOpeningModule() {
  const [apiData, setApiData] = useState([]);
  const [meta, setMeta] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isDataAvailable, setIsDataAvailable] = useState(true);
  const [isMoreDataAvailable, setIsMoreDataAvailable] = useState(true);
  const [error, setError] = useState('');
  const [jobtitle, setJobtitle] = useState('');
  const [experience, setExperience] = useState('');
  const [location, setLocation] = useState('');
  const [isFilterMode, setIsFilterMode] = useState(false);

  const [isLoading, setIsLoading] = useState(false); // Track whether data is currently being loaded

  useEffect(() => {
    fetchData(currentPage);
  }, []);

  const fetchData = async (page, append = true) => {
    try {
      setIsLoading(true);
      let response;
      if (isFilterMode) {
        let params = {
          'experience[]': experience,
          'page': page,
          'limit': itemsPerPage,
          // 'platformId': 'Website' Use IN FUTURE
        };
        if (jobtitle) {
          params.jobTitle = jobtitle;
        }
        if (location) {
          params.city = location;
        }
        response = await APIService.get("jobApiFilter", params);
      } else {
        response = await APIService.get("jobApiList", {
          page: page,
          limit: itemsPerPage,
        });
      }
      const responseData = response.data;
      setMeta(response.meta);

      if (append) {
        setApiData(prevData => [...prevData, ...responseData]);
      } else {
        setApiData(responseData);
      }

      setIsDataAvailable(responseData.length > 0);
      setIsMoreDataAvailable(response.meta.current_page < response.meta.last_page);
      setCurrentPage(response.meta.current_page);
    } catch (error) {
      console.error('Error fetching data:', error);
      setError('Error fetching data. Please try again.');
      setIsDataAvailable(false);
    } finally {
      setIsLoading(false);
    }
  };
  const classes = useStyles();
  const [state, setState] = useState({
    experience: '',
    location: '',
    name: 'hai',
  });

  const [keyword, setKeyword] = useState('');
  function onChangeHandler(e) {
    if (e.target.name === 'title') {
      const trimmedValue = e.target.value.trim();
      setJobtitle(trimmedValue)
    }
    if (e.target.name === 'experience') {
      setExperience(e.target.value)
    }
    if (e.target.name === 'location') {
      setLocation(e.target.value)
    }
  }

  // Modify the handleFilter function to check if either job title or experience is not empty
  const handleFilter = async () => {
    setError('');

    if (jobtitle || experience || location) {
      setIsLoading(true);
      setIsFilterMode(true);
      let params = {
        'experience[]': experience,
        'page': 1,
        'limit': itemsPerPage
      };
      if (jobtitle) {
        params.jobTitle = jobtitle;
      }
      if (location) {
        params.city = location;
      }

      try {
        const response = await APIService.get("jobApiFilter", params);
        setApiData(response.data);
        setIsDataAvailable(response.data.length > 0);
        setIsMoreDataAvailable(response.data.length >= itemsPerPage);
        setCurrentPage(1);
        setMeta(response.meta);
      } catch (error) {
        console.error('Error applying filters:', error);
        setError('Error applying filters. Please try again.');
        setIsDataAvailable(false);
      } finally {
        setIsLoading(false);
      }
    } else {
      setIsFilterMode(false);
      setError('Please enter at least one filter criteria');
      setIsDataAvailable(true);
      fetchData(1, false);
    }
  };

  const handleLoadMore = () => {
    if (!isLoading && isMoreDataAvailable) {
      fetchData(currentPage + 1, true);
    }
  };

  const handleClickBanner = () => {
    // Assuming that the new page has an element with the ID 'top-section'
    const topSection = document.getElementById('job-details-main-layout');

    if (topSection) {
      topSection.scrollIntoView({ behavior: 'smooth' });
    } else {
      // If the element with ID 'top-section' is not found, scroll to the top of the page
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  const handleClickApply = () => {
    // Assuming that the new page has an element with the ID 'bottom-section'
    const bottomSection = document.getElementById('submitYourApp');

    if (bottomSection) {
      bottomSection.scrollIntoView({ behavior: 'smooth', block: 'end' });
    } else {
      // If the element with ID 'bottom-section' is not found, scroll to the top of the page
      window.scrollTo({ bottom: 0, behavior: 'smooth' });
    }
  };


  const renderExperience = (row) => {
    const { minExperience, experience } = row;

    if (minExperience != null && experience != null) {
      return `${minExperience} - ${experience}`;
    } else if (minExperience == null && experience != null) {
      return experience;
    } else {
      return '-';
    }
  };
  return (
    <div className="current-opening-main">
      <h1>Be a Pitcher...</h1>
      <div className='current-openings-table-details'>
        <div className='our-current-opening-text'>Our Current Openings</div>
        <div className="table-filter">
          <div className="jobsFilterBtn">
            <input
              type="text"
              placeholder="Job Title or Keywords"
              value={jobtitle}
              name="title"
              onChange={onChangeHandler}
            />
          </div>
          <FormControl variant="filled" className={classes.formControlSelect} id="experienceFilterBtn">
            <InputLabel htmlFor="filled-experience-native-simple">Experience</InputLabel>
            <Select
              id="filled-experience-native-simpl"
              value={experience}
              label="Age"
              name="experience"
              onChange={onChangeHandler}>
              <MenuItem value="0-2">0-2 Years</MenuItem>
              <MenuItem value="2-4">2-4 Years</MenuItem>
              <MenuItem value="4-6">4-6 Years</MenuItem>
              <MenuItem value="6-8">6-8 Years</MenuItem>
              <MenuItem value="8-10">8-10 Years</MenuItem>
              <MenuItem value="10-12">10-12 Years</MenuItem>
              <MenuItem value="12-14">12-14 Years</MenuItem>
              <MenuItem value="14-16">14-16 Years</MenuItem>
              <MenuItem value="16-18">16-18 Years</MenuItem>
              <MenuItem value="18-20">18-20 Years</MenuItem>
            </Select>
          </FormControl>
          <FormControl variant="filled" className={classes.formControlSelect} id="locationFilterBtn">
            <InputLabel htmlFor="filled-location-native-simple">Location</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={location}
              name="location"
              onChange={onChangeHandler}
              inputProps={{
                name: 'location',
                id: 'filled-location-native-simple',
              }}
            >
              <MenuItem value="Noida">Noida</MenuItem>
              <MenuItem value="Bangalore">Bangalore</MenuItem>
            </Select>
          </FormControl>
          <Button variant="outline-light" id="searchBtn" onClick={handleFilter}>
            Search  <img src={bannerArrowIcon} width="15" height="auto" alt="arrow-icon" />
          </Button>
        </div>
        {error && <div className="filter-error-message"><p className="error-message">{error}</p></div>}
        <TableContainer component={Paper} className='current-openings-tableContainer'>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Job Title</StyledTableCell>
                <StyledTableCell align="right">Experience (In Years)</StyledTableCell>
                <StyledTableCell align="right">Location</StyledTableCell>
                <StyledTableCell align="right"></StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>

              {apiData.length > 0 ? (
                apiData.map((row) => (

                  <StyledTableRow key={row.jobId}>

                    <StyledTableCell component="th" scope="row" className="job-title">
                      <Link
                        to={{
                          pathname: `/job/job-description/${row.jobId}`, // Change here to include jobId in the URL
                          state: { rowData: row },
                        }}
                        onClick={handleClickBanner}
                      >
                        {row.jobTitle}
                      </Link>

                    </StyledTableCell>
                    {/* <StyledTableCell align="right">{row.minExperience} - {row.experience}</StyledTableCell> */}
                    <StyledTableCell align="right"> {renderExperience(row)}</StyledTableCell>
                    {/* <StyledTableCell align="right">{(row?.minExperience != null && row?.minExperience != undefined) ? row?.minExperience + "-" : ""} - {row.experience}</StyledTableCell> */}
                    {/* <StyledTableCell align="right">{row.location?.city?.city.split(',').join(', ')}</StyledTableCell> */}
                    <StyledTableCell align="right">{row?.jobLocation?.map((d, i) => row.jobLocation.length == (i + 1) ? d?.city?.city : d?.city?.city + ",")}</StyledTableCell>
                    <StyledTableCell align="right">
                      <Link
                        to={{
                          pathname: `/job/job-description/${row.jobId}`, // Change here to include jobId in the URL
                          state: { rowData: row },
                        }}
                        onClick={handleClickApply}
                      >
                        Apply
                      </Link>
                    </StyledTableCell>
                  </StyledTableRow>
                ))
              ) : (
                <StyledTableRow>
                  <StyledTableCell colSpan={4} align="center">
                    No data available. Please refine your search.
                  </StyledTableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <div className="paginate-load-more-btn">
          {isMoreDataAvailable ? (
            <button onClick={handleLoadMore} disabled={isLoading}>
              {isLoading ? (
                <Box sx={{ display: 'flex' }}>
                  <CircularProgress />
                </Box>
              ) : (
                'Load More'
              )}
            </button>
          ) : (
            <p>No More Jobs Available</p>
          )}
        </div>

      </div>
    </div>
  );
}

export default CurrentOpeningModule;
