import * as React from "react";

import { BrowserRouter as Router, Route, Routes  } from "react-router-dom";
import './css/style.css';
import './css/footer.css';
import './css/header.css';
import Footer from './Components/careers//careerInnerModule/Footer.js';
import Header from './Components/careers/careerInnerModule/Header.js';
import LandingTemplate from './Components/careers/LandingTemplate.js';
import footerImg from './css/images/footer/thp-footer-logo-new.svg';
import footerIcon from './css/images/footer/phone-icon.svg';
import LinkedinIcon from './css/images/footer/linkedin-icon.svg';
import TwitterIcon from './css/images/footer/twitter-logo.png';
import InstagramIcon from './css/images/footer/Instagram-icon.svg';
import FbIcon from './css/images/footer/facebook-icon.svg';
import YoutubeIcon from './css/images/footer/youtube-icon.svg';
import GlassdoorIcon from './css/images/footer/glassdoor_logo.png';
import JD from './Components/careers/careerInnerModule/Current-openning-inner-page/jobDiscription.js';



function App() {

const footerData = [
  // First Row
  [
    {
      type: 'image',
      src: footerImg,
      alt: 'Image 1',
    },
    {
      type: 'text',
      heading: 'We',
      subheadings: [
        { text: 'Increase Brand Value', url: 'https://www.thehigherpitch.com/brand-value/' },
      ],
    },
    {
      type: 'text',
      heading: 'Think',
      subheadings: [
        { text: 'Insights', url: 'https://www.thehigherpitch.com/blogs/' },
      ],
    },
    {
    
      type: 'text',
      heading: 'About',
      subheadings: [
        { text: 'The Company', url: 'https://www.thehigherpitch.com/the-company/' },
        { text: 'The Vision', url: 'https://www.thehigherpitch.com/the-company/#the-vision' },
        { text: 'The Value', url: 'https://www.thehigherpitch.com/the-company/#the-value' },
        { text: 'Leadership', url: 'https://www.thehigherpitch.com/the-company/#the-leaderShip' },
        { text: 'Contact Us', url: 'https://www.thehigherpitch.com/contact-us/' },
        { text: 'Careers', url: 'https://careers.thehigherpitch.com/' }
      ],
    },
    {
      type: 'text',
      heading: 'You',
      subheadings: [
        { text: 'Customer Success', url: 'https://www.thehigherpitch.com/customer-success/' },
      ],
    },
    // ... more columns for the first row
  ],
  // Second Row
  [
    {
      type: 'text',
      heading: 'Delhi (Reg. Office)',
      paragraph: '189, 2nd Floor Pratap Nagar, Mayur Vihar-I, Delhi-110091',
      imageSrc: footerIcon,
      phoneNumber: '+91 11 41635396',
    },
    {
      type: 'text',
      heading: 'Noida',
      paragraph: 'Unit 812 – 814, Tower – 1, Assotech Business Cresterra, Plot No. 22, Sector 135, NOIDA – 201305',
      imageSrc: footerIcon,
      phoneNumber: '+91 1205150669',
    },
    {
      type: 'text',
      heading: 'Bengaluru',
      paragraph: '178/5, 1st Floor |<br /> Lakshmi Chambers | 8th F Main Road Jayanagar 3rd Block | Bangalore - 560011',
      imageSrc: footerIcon,
      phoneNumber: '+91 – 8043749239',
    },
    {
      type: 'text',
      heading: 'USA',
      paragraph: '30 N Gould St, STE R Sheridan, WY 82801',
      imageSrc: footerIcon,
      phoneNumber: '+1 412 631 8384',
    },
  ],
  // You can add more rows if needed
];




return (
  <div className="App">


<Router>
    <Header/>
      <Routes>
        <Route path="/" element={<LandingTemplate />} />
        <Route path="/job/job-description/:id" element={<JD />} />
      </Routes>
      <div className='footer-section'>
      <div className='container-fluid'>
        <Footer data={footerData} />
      </div>
      <div class="bottom_footer">
      <div className="footer-row third-row">
        <div className="footer-colmn">
          <div className="text-column">
            <p>© 2023 The Higher Pitch | All Rights Reserved</p>
          </div>
        </div>
        <div className="footer-colmn">
          <div className="social-icons">
            <div className="icons">
              <a href='https://www.linkedin.com/company/the-higher-pitch/' target='_blank'>
                <img src={LinkedinIcon} alt="Linkedin-Icon" />
              </a>
            </div>
            <div className="icons">
            <a href='https://twitter.com/thehigherpitch/' target='_blank'>
              <img src={TwitterIcon} alt="Twitter-Icon" />
            </a>
            </div>
            <div className="icons">
            <a href='https://www.instagram.com/thehigherpitch/' target='_blank'>
              <img src={InstagramIcon} alt="Instagram-Icon" />
            </a>
            </div>
            <div className="icons">
            <a href='https://www.facebook.com/thehigherpitch/' target='_blank'>
              <img src={FbIcon} alt="Facebook-Icon" />
            </a>
            </div>
            <div className="icons">
            <a href='https://www.youtube.com/channel/UCN9zk8_AYQSbC7UV9oq6HYA/' target='_blank'>
              <img src={YoutubeIcon} alt="Youtube-Icon" />
            </a>
            </div>
            <div className="icons">
            <a href='https://www.glassdoor.co.in/Overview/Working-at-The-Higher-Pitch-EI_IE1816987.11,27.htm' target='_blank'>
              <img src={GlassdoorIcon} alt="Glassdoor-Icon" />
            </a>
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>
    </Router>
   
  </div>
);
}

export default App;
